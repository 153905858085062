<template>
  <section id="platform-settings">
    <b-row class="match-height mt-2">
      <b-col cols="9">
        <b-overlay
          variant="transparent"
          :show="showPlatformName"
        >
          <b-card no-body>
            <b-form @submit.prevent="changePlatformName(null, true)">
              <b-card-header>
                <b-card-title>
                  {{ $t("dataplatform.name")
                  }}
                  <feather-icon
                    v-b-tooltip.hover.top.v-primary="$t('dataplatform.name')"
                    icon="InfoIcon"
                    class="ml-50"
                  />
                </b-card-title>
              </b-card-header>
              <b-row>
                <b-col
                  lg="6"
                  class="ml-1"
                >
                  <b-form-group label-for="basicInput">
                    <b-form-input
                      id="platformName"
                      v-model="displayName"
                      :placeholder="$t('dataplatform.namepl')"
                      autocomplete="new-password"
                      size="lg"
                      @keyup="changePlatformName"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-button
                    type="submit"
                    variant="success"
                  >
                    {{ $t("dataGeneric.save")
                    }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              {{ $t('Invalidación') }}
              <feather-icon
                v-b-tooltip.hover.top.v-primary="$t('invalidateCach')"
                icon="InfoIcon"
              />
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col class="ml-1">
              <b-button
                variant="danger"
                @click.prevent="invalidateCache"
              >
                {{ $t('invalidateCache') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Platform features -->
    <b-row
      v-if="isSuperuser"
      class="match-height"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showFuncionalidades"
        >
          <b-card
            no-body
            class="card-platform-features p-1"
          >
            <b-card-header>
              <b-card-title>
                {{ $t("dataplatform.description") }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('function')
                  "
                  icon="InfoIcon"
                />
              </b-card-title>
            </b-card-header>
            <!-- body -->
            <b-card-body>
              <!-- features row 1 -->
              <b-row>
                <!-- features col 1 -->
                <b-col
                  v-for="(funcionalidad, index) in funcionalidades"
                  :key="index + '_' + funcionalidad.id"
                  lg="3"
                  :class="isSuperuser ? 'card-platform-feature cursor-pointer' : 'card-platform-feature'"
                >
                  <b-media
                    no-body
                    @click="isSuperuser ? changeFunction(funcionalidad) : ''"
                  >
                    <b-media-aside class="mr-90">
                      <b-avatar
                        size="70"
                        :variant="funcionalidad.value ? 'light-success' : 'light-danger'
                        "
                      >
                        <feather-icon
                          size="30"
                          :icon="funcionalidad.icon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="">
                        {{ funcionalidad.titulo }}
                        <feather-icon
                          v-b-tooltip.hover.top.v-primary
                          :title="funcionalidad.descripcion"
                          icon="InfoIcon"
                        />
                      </h6>
                      <small>{{ funcionalidad.descripcion }}</small>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- related content -->
    <b-row
      v-if="isSuperuser"
      class="match-height"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showFuncionalidades"
        >
          <b-card
            no-body
            class="card-platform-features p-1"
          >
            <b-card-header>
              <b-card-title>
                {{ $t('relatedContentType') }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('infoRelatedContentType')
                  "
                  icon="InfoIcon"
                />
              </b-card-title>
              <b-button
                variant="success"
                @click.prevent="UpdateRelatedContentType"
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="align-items-center mt-2">
                  <b-form-group :label="$t('relatedContentType')">
                    <b-form-select
                      v-model="relatedContentType"
                      :disabled="!isSuperuser"
                      :options="[
                        { value: 'TAG', text: $t('tag') },
                        { value: 'GEN', text: $t('epg.genre') },
                        { value: 'MIX', text: $t('dataGeneric.mix') },
                        { value: 'CAT', text: $t('dataGeneric.cat') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- platform type -->
    <b-row
      v-if="isSuperuser"
      class="match-height"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showFuncionalidades"
        >
          <b-card
            no-body
            class="card-platform-features p-1"
          >
            <b-card-header>
              <b-card-title>
                {{ $t('typePlat') }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('infoTypePlat')
                  "
                  icon="InfoIcon"
                />
              </b-card-title>
              <b-button
                variant="success"
                @click.prevent="UpdateType"
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="align-items-center mt-2">
                  <b-form-group :label="$t('typePlats')">
                    <b-form-select
                      v-model="clientType"
                      :disabled="!isSuperuser"
                      :options="[
                        { value: 'NOR', text: $t('NOR') },
                        { value: 'OTT', text: 'OTT' },
                        { value: 'SPO', text: $t('sport') },
                        { value: 'EDU', text: $t('edu') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Apple & Android -->
    <b-row
      v-if="isSuperuser"
      class="match-height"
    >
      <b-col>
        <b-form @submit.prevent="changeAndroidAppleConfig">
          <b-card
            no-body
            class="card-platform-features p-1"
          >
            <b-card-header>
              <b-card-title>{{ $t('AndroidAppleConfiguration') }}</b-card-title>
              <b-button
                type="submit"
                variant="success"
              >
                {{ $t("dataGeneric.save") }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-overlay
                variant="transparent"
                :show="showFuncionalidades"
              >
                <validation-observer ref="updateRules">
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="androidAppVersion"
                        :rules="{
                          regex: /^\d+$/
                        }"
                      >
                        <b-form-group
                          :label="$t('AndroidAppVersion')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="androidAppVersion"
                            v-model="androidAppVersion"
                            :placeholder="$t('AndroidAppVersion')"
                            :state="errors.length > 0 ? false : null"
                            autocomplete="new-password"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="androidTvAppVersion"
                        :rules="{
                          regex: /^\d+$/
                        }"
                      >
                        <b-form-group
                          :label="$t('androidTvAppVersion')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="androidTvAppVersion"
                            v-model="androidTvAppVersion"
                            :placeholder="$t('androidTvAppVersion')"
                            :state="errors.length > 0 ? false : null"
                            autocomplete="new-password"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="iosAppVersion"
                        :rules="{
                          regex: /^\d+$/
                        }"
                      >
                        <b-form-group
                          :label="$t('iosAppVersion')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="iosAppVersion"
                            v-model="iosAppVersion"
                            autocomplete="new-password"
                            :placeholder="$t('iosAppVersion')"
                            :state="errors.length > 0 ? false : null"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="tvosAppVersion"
                        :rules="{
                          regex: /^\d+$/
                        }"
                      >
                        <b-form-group
                          :label="$t('tvosAppVersion')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="tvosAppVersion"
                            v-model="tvosAppVersion"
                            autocomplete="new-password"
                            :placeholder="$t('tvosAppVersion')"
                            :state="errors.length > 0 ? false : null"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="androidAppDownloadUrl"
                        rules="url"
                      >
                        <b-form-group
                          :label="$t('AndroidAppDownloadUrl')"
                          :invalid-feedback="$t('urlNoValid')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="androidAppDownloadUrl"
                            v-model="androidAppDownloadUrl"
                            autocomplete="new-password"
                            :placeholder="$t('AndroidAppDownloadUrl')"
                            :state="errors.length > 0 ? false : null"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="iosAppDownloadUrl"
                        rules="url"
                      >
                        <b-form-group
                          :label="$t('AppleAppDownloadUrl')"
                          :invalid-feedback="$t('urlNoValid')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="iosAppDownloadUrl"
                            v-model="iosAppDownloadUrl"
                            :placeholder="$t('AppleAppDownloadUrl')"
                            :state="errors.length > 0 ? false : null"
                            autocomplete="new-password"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="androidTvUrl"
                        rules="url"
                      >
                        <b-form-group
                          :label="$t('androidTvUrl')"
                          :invalid-feedback="$t('urlNoValid')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="androidTvUrl"
                            v-model="androidTvUrl"
                            :placeholder="$t('androidTvUrl')"
                            :state="errors.length > 0 ? false : null"
                            autocomplete="new-password"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="amazonTvUrl"
                        rules="url"
                      >
                        <b-form-group
                          :label="$t('amazonTvUrl')"
                          :invalid-feedback="$t('urlNoValid')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="amazonTvUrl"
                            v-model="amazonTvUrl"
                            :placeholder="$t('amazonTvUrl')"
                            :state="errors.length > 0 ? false : null"
                            autocomplete="new-password"
                            maxlength="350"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-overlay>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
    <b-row
      v-if="isSuperuser && hasRating"
      class="match-height"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showFuncionalidades"
        >
          <b-card
            no-body
            class="card-platform-features p-1"
          >
            <b-card-header>
              <b-card-title>
                {{ $t('onlyrating') }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('rating')
                  "
                  icon="InfoIcon"
                />
              </b-card-title>
              <b-button
                v-if="isSuperuser"
                variant="success"
                @click.prevent="UpdateRating"
              >
                {{ $t('dataGeneric.save')
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col lg="3">
                  <b-form-group :label="$t('ratingCont')">
                    <b-form-select
                      v-model="contentRating"
                      :disabled="!isSuperuser"
                      :options="[
                        { value: 'NOR', text: $t('noRating') },
                        { value: 'LIK', text: 'Like' },
                        { value: 'STR', text: $t('Estrellas') },
                        { value: 'DEC', text: $t('puntuación') },
                        { value: 'PER', text: $t('Porcentaje') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group :label="$t('CategoryRating')">
                    <b-form-select
                      v-model="categoryRating"
                      :disabled="!isSuperuser"
                      :options="[
                        { value: 'NOR', text: $t('noRating') },
                        { value: 'LIK', text: 'Like' },
                        { value: 'STR', text: $t('Estrellas') },
                        { value: 'DEC', text: $t('puntuación') },
                        { value: 'PER', text: $t('Porcentaje') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group :label="$t('ProductsRating')">
                    <b-form-select
                      v-model="productRating"
                      :disabled="!isSuperuser"
                      :options="[
                        { value: 'NOR', text: $t('noRating') },
                        { value: 'LIK', text: 'Like' },
                        { value: 'STR', text: $t('Estrellas') },
                        { value: 'DEC', text: $t('puntuación') },
                        { value: 'PER', text: $t('Porcentaje') },
                      ]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Multi-idioma -->
    <b-row
      v-if="isSuperuser"
      class="match-height"
    >
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showLanguages"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                {{ $t('configIdiomas') }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('languages')
                  "
                  icon="InfoIcon"
                />
              </b-card-title>
              <b-button
                variant="success"
                @click.prevent="UpdateLanguages"
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-card-header>
            <b-col class="ml-0">
              <b-form-group :label="$t('idiomaDefe')">
                <b-form-select
                  v-model="defaultLanguage"
                  :placeholder="$t('idiomaDefe')"
                >
                  <b-form-select-option
                    v-for="loc in locales"
                    :key="loc.locale"
                    :value="formatToSaveDB(loc.locale)"
                  >
                    {{ loc.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group :label="$t('idiomaPlat')">
                <b-form-tags
                  v-model="value"
                  size="lg"
                  add-on-change
                  no-outer-focus
                >
                  <template
                    #default="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      removeTag
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="tag"
                          :disabled="disabledDefault(tag)"
                          variant="primary"
                          @remove="removeTag(tag)"
                        >
                          {{ tag }}
                        </b-form-tag>
                      </li>
                    </ul>
                    <b-form-select
                      v-bind="inputAttrs"
                      size="lg"
                      :options="availableOptions"
                      v-on="inputHandlers"
                    >
                      <template #first>
                        <option
                          disabled
                          value=""
                        >
                          {{ $t('disponibleLang') }}...
                        </option>
                      </template>
                    </b-form-select>
                  </template>
                </b-form-tags>
              </b-form-group>
            </b-col>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- URL Login-->
    <b-row class="match-height">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showUrlLogin"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                {{ $t("dataplatform.url") }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('urlLog')
                  "
                  icon="InfoIcon"
                />
              </b-card-title>
            </b-card-header>

            <validation-observer ref="accountRules">
              <b-form-row>
                <b-col
                  cols="6"
                  class="ml-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="urlLogin"
                    rules="url"
                  >
                    <b-form-group
                      label-for="basicInput"
                      invalid-feedback="Url no válida"
                    >
                      <b-form-input
                        id="urlLogin"
                        v-model="urlLogin"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        size="lg"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  lg="3"
                  class="ml-2"
                >
                  <b-button
                    variant="success"
                    @click="changeUrlLoginPromise()"
                  >
                    {{
                      $t("dataGeneric.save")
                    }}
                  </b-button>
                </b-col>
              </b-form-row>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Datos de contacto -->
    <b-row class="match-height">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showContact"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>{{ $t("dataplatform.data") }} </b-card-title>
            </b-card-header>
            <validation-observer ref="emailRules">
              <b-form-row>
                <b-col
                  cols="6"
                  class="ml-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="mail"
                    rules="email"
                  >
                    <b-form-group
                      label-for="basicInput"
                      :invalid-feedback="$t('required')"
                    >
                      <b-form-input
                        id="mail"
                        v-model="mailContacto"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        size="lg"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  lg="3"
                  class="ml-2"
                >
                  <b-button
                    variant="success"
                    @click="changeEmailPromise()"
                  >
                    {{
                      $t("dataGeneric.save")
                    }}
                  </b-button>
                </b-col>
              </b-form-row>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
      <!-- URL to share-->
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showUrlToShare"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>{{ $t('urlBase') }} </b-card-title>
            </b-card-header>
            <validation-observer ref="urlContentsRules">
              <b-form-row>
                <b-col
                  cols="6"
                  class="ml-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="urlToShare"
                    rules="url"
                  >
                    <b-form-group
                      label-for="basicInput"
                      :invalid-feedback="$t('urlNoValid')"
                    >
                      <b-form-input
                        id="urlToShare"
                        v-model="urlToShare"
                        :state="errors.length > 0 ? false : null"
                        size="lg"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  lg="3"
                  class="ml-2"
                >
                  <b-button
                    variant="success"
                    @click="changeUrlContentsPromise()"
                  >
                    {{
                      $t("dataGeneric.save")
                    }}
                  </b-button>
                </b-col>
              </b-form-row>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- social networks -->
    <b-row class="match-height">
      <b-col>
        <settings-tabs-social-media />
      </b-col>
    </b-row>
    <!-- Platform limits -->
    <b-row class="match-height">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showLimits"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                {{ $t("dataplatform.platfLimit") }}
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('limitPL')"
                  icon="InfoIcon"
                />
              </b-card-title>
            </b-card-header>
            <b-col class="ml-0">
              <settings-limits-table :table-data="platformLimits" />
            </b-col>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  VBTooltip,
  BFormInput,
  BFormTags,
  BFormTag,
  BFormGroup,
  BFormSelect,
  BAvatar,
  BMedia,
  BFormSelectOption,
  BMediaAside,
  BMediaBody,
  BButton,
  BForm,
  BFormRow,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, setClientNode } from '@/store/functions'

import { getUserData } from '@/auth/utils'
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import localesLanguages from '@core/utils/languageModels'
import SettingsLimitsTable from './SettingsLimitsTable.vue'
import SettingsTabsSocialMedia from './SettingsTabsSocialMedia.vue'

export default {
  components: {
    BForm,
    BFormRow,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormInput,
    BFormTags,
    BFormSelectOption,
    BFormTag,
    BFormGroup,
    BFormSelect,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton,
    SettingsTabsSocialMedia,
    BOverlay,
    ValidationProvider,
    SettingsLimitsTable,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    options: [],
    value: [],
    required,
    platformLimits: [],
    displayName: null,
    funcionalidades: [],
    urlLogin: null,
    mailContacto: null,
    showPlatformName: true,
    showFuncionalidades: true,
    showContact: true,
    showUrlLogin: true,
    showCountries: true,
    showLanguages: true,
    selected: 'CON',
    showLimits: true,
    clientType: 'NOR',
    urlToShare: null,
    showUrlToShare: false,
    userData: getUserData(),
    isSuperuser: getUserData().isSuperuser,
    languagesValue: [],
    locales: localesLanguages,
    defaultLanguage: '',
    contentRating: 'NOR',
    productRating: 'NOR',
    categoryRating: 'NOR',
    relatedContentType: 'GEN',
    hasRating: false,
    androidAppVersion: 0,
    tvosAppVersion: 0,
    androidTvAppVersion: 0,
    iosAppVersion: 0,
    androidAppDownloadUrl: '',
    iosAppDownloadUrl: '',
    androidTvUrl: '',
    amazonTvUrl: '',
  }),
  computed: {
    availableOptions() {
      const selectOptions = this.options.filter(opt => this.value.indexOf(opt.name) === -1)
      return selectOptions.map(a => a.name)
    },
  },
  watch: {
    defaultLanguage() {
      localesLanguages.forEach(element => {
        if (element.locale === this.getLocaleFormat(this.defaultLanguage)[0]) {
          if (!this.value.includes(element.name)) {
            this.value.push(element.name)
          }
        }
      })
    },
  },
  mounted() {
    this.getGeneralData()
  },
  methods: {
    disabledDefault(value) {
      const defLang = this.getLocaleFormat(this.defaultLanguage)[0]
      const matchingLocale = this.locales.find(element => element.locale === defLang)

      if (matchingLocale && matchingLocale.name === value) {
        return true
      }
      return false
    },
    getIdLanguages(array) {
      const languagesSelected = this.options.filter(opt => array.indexOf(opt.name) !== -1)
      const languages = languagesSelected.map(a => this.formatToSaveDB(a.id)).join('|')

      return languages
    },
    saveLanguages(languages) {
      const { id } = this.userData.profile.client
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          availableLanguages: "${this.getIdLanguages(languages)}",
          defaultLanguage:"${this.defaultLanguage}"
        }){
          client{
            id
            name
            videotype
            video
          
          }
        }
      }
      `
      axios
        .post('', { query }).then(result => {
          messageError(result, this)
          setClientNode()

          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    UpdateRelatedContentType() {
      const { id } = this.userData.profile.client
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          relatedContentType: ${this.relatedContentType},
        }){
          client{
            id
            relatedContentType          
          }
        }
      }
      `
      axios
        .post('', { query }).then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    UpdateType() {
      const { id } = this.userData.profile.client
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          type: ${this.clientType},
        }){
          client{
            id
            type          
          }
        }
      }
      `
      axios
        .post('', { query }).then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          setClientNode()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    changeAndroidAppleConfig(event) {
      event.preventDefault()

      return new Promise(resolve => {
        this.$refs.updateRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateAndroidAppleConfig()
          } else {
            showToast(this.$t('error'), 2, this)
          }
        })
      })
    },
    updateAndroidAppleConfig() {
      const data = new FormData()
      const { headers } = this
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
        mutation ($client: ID!, $androidAppVersion: Int!,
        $androidTvAppVersion: Int!,
        $iosAppVersion: Int!,
        $tvosAppVersion: Int!,
        $androidAppDownloadUrl: String!, $iosAppDownloadUrl: String!,$androidTvUrl: String!,$amazonTvUrl: String!) {
          updateClient(id:$client, input:{
            androidAppVersion: $androidAppVersion,
            androidTvAppVersion: $androidTvAppVersion,
            iosAppVersion: $iosAppVersion,
            tvosAppVersion: $tvosAppVersion,
            androidAppDownloadUrl: $androidAppDownloadUrl,
            iosAppDownloadUrl: $iosAppDownloadUrl
            androidTvUrl: $androidTvUrl
            amazonTvUrl: $amazonTvUrl
          }){
            client{
              id
              type          
            }
          }
        }
      `
      const variables = {
        client: this.userData.profile.client.id,
        androidAppVersion: parseInt(this.androidAppVersion, 10),
        iosAppVersion: parseInt(this.iosAppVersion, 10),
        androidTvAppVersion: parseInt(this.androidTvAppVersion, 10),
        tvosAppVersion: parseInt(this.tvosAppVersion, 10),
        androidAppDownloadUrl: this.androidAppDownloadUrl,
        iosAppDownloadUrl: this.iosAppDownloadUrl,
        androidTvUrl: this.androidTvUrl,
        amazonTvUrl: this.amazonTvUrl,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          // setClientNode()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    UpdateRating() {
      const { id } = this.userData.profile.client
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          contentRating: ${this.contentRating},
          categoryRating: ${this.categoryRating},
          productRating: ${this.productRating},
        }){
          client{
            id
            name
            videotype
            video
          
          }
        }
      }
      `
      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    UpdateLanguages() {
      this.saveLanguages(this.value)
    },
    onlyLatinSpacesDots(str) {
      return /^[a-zA-ZÀ-ÿ0-9\s.,]+$/.test(str)
    },
    ...mapActions('verticalMenu', ['alterName']),

    changeFunction(data) {
      this.showFuncionalidades = true

      const { id } = this.userData.profile.client
      const query = `
        mutation{
          updateClient(id:"${id}",input:{${data.id}:${!data.value}}){
            client{
              id
              name
              isShop
              hasProfiles
              isBuy
              isInteractivity
              isFavorite
              videotype
              isSecondScreenInteractivity
              isPushInteractivity
              isBlockInteractivity
              isElearningPlatform
              hasMyChannel
              isFastChannel
              hasReels
              isVirtualUser
              isClose
              isPromotionalCodes
              isDownload
              isParentalControl
              isAds
              hasRating
              hasAdSlides
              hasAdCuePoints
              contentRating
              productRating
              categoryRating
              isLinkCategories
              isLive
              isTranscoding
              geoipbloking
              hasDisplayAds
            }
          }
        }
      `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          const dataRes = result.data.data.updateClient.client
          this.hasRating = dataRes.hasRating
          const resFunciones = [
            {
              value: dataRes.isClose,
              titulo: this.$t('resFunciones.isClose.title'),
              descripcion: this.$t('resFunciones.isClose.description'),
              icon: !dataRes.isClose ? 'LockIcon' : 'UnlockIcon',
              id: 'isClose',
            },
            {
              value: dataRes.hasProfiles,
              titulo: this.$t('Perfiles'),
              descripcion: this.$t('platPerf'),
              icon: 'UsersIcon',
              id: 'hasProfiles',
            },
            {
              value: dataRes.isFastChannel,
              titulo: 'Fast channel',
              descripcion: this.$t('isFastChannel'),
              icon: 'TvIcon',
              id: 'isFastChannel',
            },
            {
              value: dataRes.isDownload,
              titulo: this.$t('contDownlable'),
              descripcion: this.$t('canDownloadCont'),
              icon: 'DownloadIcon',
              id: 'isDownload',
            },
            {
              value: dataRes.isElearningPlatform,
              titulo: this.$t('learning'),
              descripcion: this.$t('learningInfo'),
              icon: 'BookOpenIcon',
              id: 'isElearningPlatform',
            },
            {
              value: dataRes.hasMyChannel,
              titulo: this.$t('myChannel'),
              descripcion: this.$t('myChannelplat'),
              icon: 'TvIcon',
              id: 'hasMyChannel',
            },
            {
              value: dataRes.hasReels,
              titulo: this.$t('reels.reels'),
              descripcion: this.$t('hasReelsplat'),
              icon: 'SmartphoneIcon',
              id: 'hasReels',
            },
            {
              value: dataRes.isPromotionalCodes,
              titulo: this.$t('Códigos promocionales'),
              descripcion: this.$t('hasCod'),
              icon: 'PercentIcon',
              id: 'isPromotionalCodes',
            },
            {
              value: dataRes.isAds,
              titulo: this.$t('settingTabs.ads'),
              descripcion: this.$t('existAds'),
              icon: 'MonitorIcon',
              id: 'isAds',
            },
            {
              value: dataRes.isShop,
              titulo: this.$t('Shop'),
              descripcion: this.$t('resFunciones.isShop.description'),
              icon: 'ShoppingBagIcon',
              id: 'isShop',
            },
            {
              value: dataRes.isBuy,
              titulo: this.$t('resFunciones.isBuy.title'),
              descripcion: this.$t('resFunciones.isBuy.description'),
              icon: 'ShoppingCartIcon',
              id: 'isBuy',
            },
            {
              value: dataRes.hasRating,
              titulo: this.$t('resFunciones.hasRating.title'),
              descripcion: this.$t('resFunciones.hasRating.description'),
              icon: 'StarIcon',
              id: 'hasRating',
            },

            {
              value: dataRes.hasAdSlides,
              titulo: this.$t('resFunciones.hasAdSlides.title'),
              descripcion: this.$t('resFunciones.hasAdSlides.description'),
              icon: 'MonitorIcon',
              id: 'hasAdSlides',
            },
            {
              value: dataRes.hasAdCuePoints,
              titulo: this.$t('resFunciones.hasAdCuePoints.title'),
              descripcion: this.$t('resFunciones.hasAdCuePoints.description'),
              icon: 'AirplayIcon',
              id: 'hasAdCuePoints',
            },
            {
              value: dataRes.isLive,
              titulo: this.$t('resFunciones.isLive.title'),
              descripcion: this.$t('resFunciones.isLive.description'),
              icon: 'RadioIcon',
              id: 'isLive',
            },
            {
              value: dataRes.isTranscoding,
              titulo: this.$t('transcoding.transcoding'),
              descripcion: this.$t('transcoding.transcodingAllowed'),
              icon: 'FilmIcon',
              id: 'isTranscoding',
            },
            {
              value: dataRes.geoipbloking,
              titulo: this.$t('zones.title'),
              descripcion: this.$t('geoipbloking'),
              icon: 'MapPinIcon',
              id: 'geoipbloking',
            },
            {
              value: dataRes.hasDisplayAds,
              titulo: this.$t('adsDisplay.title'),
              descripcion: this.$t('adsDisplay.description'),
              icon: 'ExternalLinkIcon',
              id: 'hasDisplayAds',
            },
          ]
          this.funcionalidades = resFunciones.sort((a, b) => {
            const tituloA = a.titulo.toUpperCase()
            const tituloB = b.titulo.toUpperCase()

            if (tituloA < tituloB) {
              return -1
            }
            if (tituloA > tituloB) {
              return 1
            }
            return 0
          })
          setClientNode()
          this.showFuncionalidades = false
        })
        .catch(() => { })
    },
    invalidateCache() {
      const { id } = this.userData.profile.client
      let clientId = ''
      try {
        // eslint-disable-next-line prefer-destructuring
        clientId = atob(id).split(':')[1]
        // eslint-disable-next-line no-empty
      } catch (error) { }

      this.$swal({
        title: this.$t('Resetear Cache'),
        text: this.isSuperuser ? this.$t('sureReset') : this.$t('ifCont'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `mutation($id: String!){
                resetCache(clientId:$id){
                  time
                  reset
                }
              }`,
              variables: { id: clientId },
            })
            .then(res => {
              messageError(res, this)
              const { resetCache } = res.data.data
              const restTime = resetCache.time / 60

              if (resetCache.reset) {
                showToast(this.$t('success'), 1, this)
              } else {
                this.$swal({
                  title: this.$t('Resetear Cache'),
                  text: this.$t('resetCache', { time: Math.floor(restTime) }),
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: this.$t('entendido'),
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      }).catch(() => {

      })
    },
    formatToSaveDB(languagesString) {
      const partes = []
      for (let i = 0; i < languagesString.length; i += 2) {
        partes.push(languagesString.slice(i, i + 2).toLowerCase())
      }
      return partes.join('-')
    },
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    getLanguagesPlatform(languagesString) {
      const idiomasFormateados = this.getLocaleFormat(languagesString)
      const coincidencias = localesLanguages.filter(idioma => idiomasFormateados.includes(idioma.locale)).map(idioma => idioma.name)
      this.languagesValue = [
        ...coincidencias,
      ]
      this.value = [
        ...coincidencias,
      ]
    },
    getGeneralData() {
      const query = `
        query {
          client (id: "${this.userData.profile.client.id}"){
            id
            name
            relatedContentType
            defaultLanguage
            availableLanguages
            displayName
            webUrl
            hasProfiles
            hasRating
            hasAdSlides
            hasAdCuePoints
            contentRating
            productRating
            categoryRating
            isShop
            isBuy
            isInteractivity
            type
            isFavorite
            videotype
            isSecondScreenInteractivity
            isPushInteractivity
            isBlockInteractivity
            isVirtualUser
            isClose
            isDownload
            isFastChannel
            isElearningPlatform
            hasMyChannel
            hasReels
            isPromotionalCodes
            isParentalControl
            isAds
            isLinkCategories
            isLive
            isTranscoding
            geoipbloking
            hasDisplayAds
            emailContact
            countries {
              edges {
                node {
                  id
                  country
                }
              }
            }
            loginUrl
            sizeFileLimit
            usedFileSize
            totalVideos
            totalProducts
            totalInteractivity
            contentInteractivityLimit
            initialCatLimit
            nestedCatLimit
            initialCatProductLimit
            nestedCatProductLimit
            productsCatLimit
            androidAppVersion
            androidTvAppVersion
            iosAppVersion
            tvosAppVersion
            androidAppDownloadUrl
            iosAppDownloadUrl
            androidTvUrl
            amazonTvUrl
          }
          allContents {
            totalCount
          }
          allProducts {
            totalCount
          }
          allInteractivities {
            totalCount
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const dataRes = res.data.data.client
        const totalProducts = res.data.data.allProducts.totalCount
        const totalContents = res.data.data.allContents.totalCount
        const totalInteractivities = res.data.data.allInteractivities.totalCount
        this.selected = dataRes.videotype
        this.hasRating = dataRes.hasRating
        this.clientType = dataRes.type
        this.contentRating = dataRes.contentRating
        this.productRating = dataRes.productRating
        this.categoryRating = dataRes.categoryRating
        this.relatedContentType = dataRes.relatedContentType
        this.androidAppVersion = dataRes.androidAppVersion
        this.androidTvAppVersion = dataRes.androidTvAppVersion
        this.iosAppVersion = dataRes.iosAppVersion
        this.tvosAppVersion = dataRes.tvosAppVersion
        this.androidAppDownloadUrl = dataRes.androidAppDownloadUrl
        this.iosAppDownloadUrl = dataRes.iosAppDownloadUrl
        this.androidTvUrl = dataRes.androidTvUrl
        this.amazonTvUrl = dataRes.amazonTvUrl
        const resFunciones = [
          {
            value: dataRes.isClose,
            titulo: this.$t('resFunciones.isClose.title'),
            descripcion: this.$t('resFunciones.isClose.description'),
            icon: !dataRes.isClose ? 'LockIcon' : 'UnlockIcon',
            id: 'isClose',
          },
          {
            value: dataRes.hasProfiles,
            titulo: this.$t('Perfiles'),
            descripcion: this.$t('platPerf'),
            icon: 'UsersIcon',
            id: 'hasProfiles',
          },
          {
            value: dataRes.isFastChannel,
            titulo: 'Fast channel',
            descripcion: this.$t('isFastChannel'),
            icon: 'TvIcon',
            id: 'isFastChannel',
          },
          {
            value: dataRes.isDownload,
            titulo: this.$t('contDownlable'),
            descripcion: this.$t('canDownloadCont'),
            icon: 'DownloadIcon',
            id: 'isDownload',
          },
          {
            value: dataRes.isElearningPlatform,
            titulo: this.$t('learning'),
            descripcion: this.$t('learningInfo'),
            icon: 'BookOpenIcon',
            id: 'isElearningPlatform',
          },
          {
            value: dataRes.hasMyChannel,
            titulo: this.$t('myChannel'),
            descripcion: this.$t('myChannelplat'),
            icon: 'TvIcon',
            id: 'hasMyChannel',
          },
          {
            value: dataRes.hasReels,
            titulo: this.$t('reels.reels'),
            descripcion: this.$t('hasReelsplat'),
            icon: 'SmartphoneIcon',
            id: 'hasReels',
          },
          {
            value: dataRes.isPromotionalCodes,
            titulo: this.$t('Códigos promocionales'),
            descripcion: this.$t('hasCod'),
            icon: 'PercentIcon',
            id: 'isPromotionalCodes',
          },
          {
            value: dataRes.isAds,
            titulo: this.$t('settingTabs.ads'),
            descripcion: this.$t('existAds'),
            icon: 'MonitorIcon',
            id: 'isAds',
          },
          {
            value: dataRes.isShop,
            titulo: this.$t('Shop'),
            descripcion: this.$t('resFunciones.isShop.description'),
            icon: 'ShoppingBagIcon',
            id: 'isShop',
          },
          {
            value: dataRes.isBuy,
            titulo: this.$t('resFunciones.isBuy.title'),
            descripcion: this.$t('resFunciones.isBuy.description'),
            icon: 'ShoppingCartIcon',
            id: 'isBuy',
          },
          {
            value: dataRes.hasRating,
            titulo: this.$t('resFunciones.hasRating.title'),
            descripcion: this.$t('resFunciones.hasRating.description'),
            icon: 'StarIcon',
            id: 'hasRating',
          },
          {
            value: dataRes.hasAdSlides,
            titulo: this.$t('resFunciones.hasAdSlides.title'),
            descripcion: this.$t('resFunciones.hasAdSlides.description'),
            icon: 'MonitorIcon',
            id: 'hasAdSlides',
          },
          {
            value: dataRes.hasAdCuePoints,
            titulo: this.$t('resFunciones.hasAdCuePoints.title'),
            descripcion: this.$t('resFunciones.hasAdCuePoints.description'),
            icon: 'AirplayIcon',
            id: 'hasAdCuePoints',
          },
          {
            value: dataRes.isLive,
            titulo: this.$t('resFunciones.isLive.title'),
            descripcion: this.$t('resFunciones.isLive.description'),
            icon: 'RadioIcon',
            id: 'isLive',
          },
          {
            value: dataRes.isTranscoding,
            titulo: this.$t('transcoding.transcoding'),
            descripcion: this.$t('transcoding.transcodingAllowed'),
            icon: 'FilmIcon',
            id: 'isTranscoding',
          },
          {
            value: dataRes.geoipbloking,
            titulo: this.$t('zones.title'),
            descripcion: this.$t('geoipbloking'),
            icon: 'MapPinIcon',
            id: 'geoipbloking',
          },
          {
            value: dataRes.hasDisplayAds,
            titulo: this.$t('adsDisplay.title'),
            descripcion: this.$t('adsDisplay.description'),
            icon: 'ExternalLinkIcon',
            id: 'hasDisplayAds',
          },
        ]
        this.displayName = dataRes.displayName
        this.urlToShare = dataRes.webUrl
        this.urlLogin = dataRes.loginUrl
        this.mailContacto = dataRes.emailContact
        localesLanguages.forEach(item => {
          this.options.push({ id: item.locale, name: item.name })
        })
        this.getLanguagesPlatform(dataRes.availableLanguages)

        this.funcionalidades = resFunciones.sort((a, b) => {
          const tituloA = a.titulo.toUpperCase()
          const tituloB = b.titulo.toUpperCase()

          if (tituloA < tituloB) {
            return -1
          }
          if (tituloA > tituloB) {
            return 1
          }
          return 0
        })

        this.defaultLanguage = dataRes.defaultLanguage
        this.platformLimits = [
          {
            name: this.$t('code.cuota'),
            description: this.$t('code.des'),
            available: dataRes.sizeFileLimit,
            used: dataRes.usedFileSize,
            progress: this.getProgressValue(
              dataRes.sizeFileLimit,
              dataRes.usedFileSize,
            ),
            canIncrement: true,
          },
          {
            name: this.$t('code.allCont'),
            description: this.$t('code.des'),
            available: dataRes.totalVideos,
            used: totalContents,
            progress: this.getProgressValue(dataRes.totalVideos, totalContents),
            canIncrement: true,
          },
          {
            name: this.$t('code.allProd'),
            description: this.$t('code.des'),
            available: dataRes.totalProducts,
            used: totalProducts,
            progress: this.getProgressValue(
              dataRes.totalProducts,
              totalProducts,
            ),
            canIncrement: true,
          },
          {
            name: this.$t('code.allInt'),
            description: this.$t('code.des'),
            available: dataRes.totalInteractivity,
            used: totalInteractivities,
            progress: this.getProgressValue(
              dataRes.totalInteractivity,
              totalInteractivities,
            ),
            canIncrement: true,
          },
          {
            name: this.$t('code.intPerCont'),
            description: this.$t('code.des'),
            available: dataRes.contentInteractivityLimit,
            used: '-',
            progress: '-',
            canIncrement: true,
          },
          {
            name: this.$t('code.catInit'),
            description: this.$t('code.des'),
            available: dataRes.initialCatLimit,
            used: '-',
            progress: '-',
            canIncrement: true,
          },
          {
            name: this.$t('code.catAni'),
            description: this.$t('code.des'),
            available: dataRes.nestedCatLimit,
            used: '-',
            progress: '-',
            canIncrement: true,
          },
          {
            name: this.$t('code.catInitProd'),
            description: this.$t('code.des'),
            available: dataRes.initialCatProductLimit,
            used: '-',
            progress: '-',
            canIncrement: true,
          },
          {
            name: this.$t('code.catAniProd'),
            description: this.$t('code.des'),
            available: dataRes.nestedCatProductLimit,
            used: '-',
            progress: '-',
            canIncrement: true,
          },
          {
            name: this.$t('code.prodPerCat'),
            description: this.$t('code.des'),
            available: dataRes.productsCatLimit,
            used: '-',
            progress: '-',
            canIncrement: true,
          },
        ]
        this.showPlatformName = false
        this.showFuncionalidades = false
        this.showContact = false
        this.showUrlLogin = false
        this.showCountries = false
        this.showLanguages = false
        this.showLimits = false
        this.$emit('return')
      })
    },
    getProgressValue(limitValue, usedValue) {
      return (usedValue * 100) / limitValue
    },
    changePlatformName(event = null, pass = false) {
      let keycode = 0
      if (event) { keycode = event.keyCode || event.which }
      if (keycode === 13 || pass) {
        if (this.onlyLatinSpacesDots(this.displayName)) {
          this.changeDisplayName()
        } else {
          showToast(this.$t('errorAlfa'), 2, this)
        }
      }
    },
    changeDisplayName() {
      this.showPlatformName = true
      const query = `
        mutation($id: ID!, $displayName: String){
          updateClient(id: $id, input: {
            displayName: $displayName
          }){
            client {
              id
              displayName
            }
          }
        }
      `
      const variables = {
        id: this.userData.profile.client.id,
        displayName: this.displayName,
      }

      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.change', {
              data: res.data.data.updateClient.client.displayName,
            }),
            1, this,
          )
          this.alterName(this.displayName)
        })
        .catch(() => {
          showToast(this.$t('code.error'), 2, this)
        })
      this.showPlatformName = false
    },
    changeUrlLoginPromise() {
      return new Promise(resolve => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.changeUrlLogin()
          } else {
            showToast(this.$t('error'), 2, this)
          }
        })
      })
    },
    changeUrlLogin() {
      this.showUrlLogin = true
      const query = `
         mutation($id: ID!, $loginUrl: String){
           updateClient(id: $id, input: {
             loginUrl: $loginUrl
           }){
             client {
               id
               displayName
             }
           }
         }
       `
      const variables = {
        id: this.userData.profile.client.id,
        loginUrl: this.urlLogin,
      }

      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.urlCh'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorUrl'), 2, this)
        })
      this.showUrlLogin = false
    },
    changeEmailPromise() {
      return new Promise(resolve => {
        this.$refs.emailRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateContactData()
          } else {
            showToast(this.$t('error'), 2, this)
          }
        })
      })
    },
    updateContactData() {
      this.showContact = true
      const query = `
        mutation($id: ID!, $mailContact: String){
          updateClient(id: $id, input: {
            emailContact: $mailContact
          }){
            client {
              id
              displayName
            }
          }
        }
      `
      const variables = {
        id: this.userData.profile.client.id,
        mailContact: this.mailContacto,
      }

      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.mod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorMod'), 2, this)
        })
      this.showContact = false
    },
    changeUrlContentsPromise() {
      return new Promise(resolve => {
        this.$refs.urlContentsRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.changeUrlToShare()
          } else {
            showToast(this.$t('error'), 2, this)
          }
        })
      })
    },
    changeUrlToShare() {
      this.showUrlLogin = true
      const query = `
        mutation($id: ID!, $webUrl: String){
          updateClient(id: $id, input: {
            webUrl: $webUrl
          }){
            client {
              id
              webUrl
            }
          }
        }
      `
      const variables = {
        id: this.userData.profile.client.id,
        webUrl: this.urlToShare,
      }

      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.urlCh'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorUrl'), 2, this)
        })
      this.showUrlLogin = false
    },

  },
}
</script>

<style lang="scss" scoped>
#platform-settings .card-platform-feature {
  margin-top: 1rem;
}

#platform-settings input {
  background-color: #ffffff;
  color: #000000;
}
</style>
